export function routeWithDifferentLocale(route: any, locale: string) {
  const newName = getRouteNameWithLangSuffix(stripRouteNameSuffix(route.name), locale)
  return { ...route, name: newName }
}

// copying the pension depot middleware and making it a dyanmic middleware
export default defineNuxtRouteMiddleware((to) => {
  const { $i18n } = useNuxtApp()

  // If the locale is Hebrew, we do nothing
  if ($i18n.locale.value === "he") return

  // If the route is the pension depot, we force redirect to the Hebrew version
  return routeWithDifferentLocale(to, "he")
})
